var jdiGlobalObj = {
   init: function() {
      this.cacheDOM();
      this.bindEvents();
      if ($('.video-player.youtube').length) {
         this.YTplayBtns = $('.video-player.youtube').closest('.cover').find('.cover_play-btn');
         this.YTplayBtns.on('click', this.playYTIframe);
      }
      if ($('.video-player.vimeo').length) {
         this.vimeoPlayBtns = $('.video-player.vimeo').closest('.cover').find('.cover_play-btn');
         this.vimeoPlayBtns.on('click', this.playVimeoIframe);
         this.getVimeoThumbs();
         //Auto Play
         this.vimeoPlayBtns.click();
      }
      if ($('.vimeo-iframe-container').length) {
         this.$iwtVimeo = $(".vimeo-iframe-container");
         this.playVimeoIframe2();
      }
      if ($(".youtube-iframe-container").length) {
         this.$iwtYt = $(".youtube-iframe-container");
         this.playYTIframe2();
      }
      if ($(".codesnippetblock").length) {
         this.getiFrameheight()
      }
      if ($('.text-w-img.has-light-box').length) {
         this.$textWImgOpenBtns = $('.text-w-img_lightbox-btn');
         this.$textWImgOpenBtns.on('click', this.handleTextWImgLightboxOpen.bind(this));
         this.$textWImgOpenImg = $('.text-w-img_img'); 
         this.$textWImgOpenImg.on('click', this.handleTextWImgLightboxOpen.bind(this));
         this.$textWImgCloseBtns = $('.text-w-img_lightbox-close-btn');
         this.$textWImgCloseBtns.on('click', this.handleTextWImgLightboxClose.bind(this));
      }
      if ($('.accordion').length) {
         this.$accordionItems = $('.accordion_item-title-wrap');
         this.$accordionItems.on('click', this.accordionHandler.bind(this));
      }
      if ($('.rotator').length) {
         this.$rotators = $('.rotator');
         this.initRotators();
      }
      if ($('.ticker').length) {
         this.$tickers = $('.ticker');
         this.initTickers();
      }
      if ($('.media-gallery').length) {
         this.$mediaGallerys = $('.media-gallery');
         this.initMediaGalleries();
      }
      if ($('.news-rotator').length) {
         this.$newsRotators = $('.news-rotator');
         this.initNewsRotators();
       }
       if ($('#categoryForm').length) {
           this.$newsCategoryForm = $("#categoryForm");
           this.$categoryIdField = $("#newsCategoryId");
       }
      if ($('.tabs').length) {
         this.$tabs = $('.tabs');
         this.$tabsHeaders = this.$tabs.find('.tabs_header-item');
         this.$tabsContents = this.$tabs.find('.tabblock');
         this.$tabsHeaders.on('click', this.tabsClickHandler.bind(this));
      }
   },
   cacheDOM: function() {
      this.$mainNav = $('#main-nav');
      this.$hamburger = this.$mainNav.find('.hamburger');
      this.$mobileNavLinksCont = this.$mainNav.find('.main-nav_links-col');
      this.$mainNavLogoBG = this.$mainNav.find('.main-nav_logo-bg');
      this.navLogoUrl = this.$mainNavLogoBG.attr('data-logo');
      this.scrolledNavLogoUrl = this.$mainNavLogoBG.attr('data-scrolled-logo');
      if (this.$mainNav.find('.main-nav_mobile-nav-submenu-expand').length) {
         this.$submenuExpandBtns = this.$mainNav.find('.main-nav_mobile-nav-submenu-expand');
      }
   },
   bindEvents: function() {
      this.navScrollHandler();
      this.shouldSmoothScroll();
      $(window).on('scroll', this.navScrollHandler.bind(this));
      this.$hamburger.on('click', this.handleMobileNav.bind(this));
      if (this.$submenuExpandBtns && this.$submenuExpandBtns.length) {
         this.$submenuExpandBtns.on('click', this.handleSubmenuExpand.bind(this));
      }
   },
   navScrollHandler: function() {
            
      //activate text underline on/off
      var $navHover = $("#main-nav li .main-nav_link-lvl-1, #main-nav li .main-nav_link-lvl-2")
      $navHover.on("mouseover", function() {
         $(this).siblings("dev[class^="text-underline"]").addClass("animate-in").removeClass("animate-out")
      })

      $navHover.on("mouseout", function() {
         $(this).siblings("#nav-underline").addClass("animate-out").removeClass("animate-in")
      })

      var scrollTop = $(window).scrollTop();
      var _this = this;
      if (scrollTop > 0) {
         this.$mainNav.addClass('is-scrolled');
         //scrolling text underline conditional loading
         $(".text-underline").hide()
         $(".text-underline-scrolled").show()
         $("#logo-no-scroll").hide()
         $("#logo-scrolled").show()
         this.$mainNavLogoBG.css('background-image', 'url(' + _this.scrolledNavLogoUrl + ')');
      } else {
         this.$mainNav.removeClass('is-scrolled');
         //scrolling text underline conditional loading
         $(".text-underline").show()
         $(".text-underline-scrolled").hide()
         $("#logo-scrolled").hide()
         $("#logo-no-scroll").show()
         this.$mainNavLogoBG.css('background-image', 'url(' + _this.navLogoUrl + ')');
      }
   },
   shouldSmoothScroll: function() {
      if($(location.hash).length) {
         var _this = this;
         setTimeout(function() {
            $('html,body').animate({ scrollTop: $(location.hash).offset()['top'] - _this.$mainNav.height() * 1.2 }, 500);
         }, 350)
      }
   },
   handleSubmenuExpand: function(e) {
      var $target = $(e.target);
      $target.toggleClass('is-active fa-angle-down fa-angle-up');
      $target.parents('li').find('.main-nav_cat-lvl-2').slideToggle(350);
   },
   handleMobileNav: function(e) {
      this.$hamburger.toggleClass('is-active');
      this.$mobileNavLinksCont.toggleClass('is-open');
   },
   initNewsRotators: function() {
      this.$newsRotators.each(function(i, item){
         var id = $(item).attr("id");
         if(!(window.ActiveXObject) && "ActiveXObject" in window){
            var $nextBut = $(item).find('.swiper-button-next');
            var $prevBut = $(item).find('.swiper-button-prev');       
            $nextBut.attr({style:"background-position: 50% 50% !important;top: 95% !important;"});
            $prevBut.attr({style:"background-position: 50% 50% !important;top: 95% !important;"});
          } else {
        //do nothing
         }
         new Swiper($(item).find('.swiper-container'), {
            speed: 850,
            pagination: {
               el: '#' +id+ ' .swiper-pagination',
               type: 'bullets',
               clickable: true
               },
               navigation: {
               nextEl: '#' +id+ ' .swiper-button-next',
               prevEl: '#' +id+ ' .swiper-button-prev',
               },
               autoplay: {
                   delay: 5000,

               },
               loop:true
         })
      })
   },
   initRotators: function() {
      this.$rotators.each(function(i, item){
         var id = $(item).attr("id");
         if(!(window.ActiveXObject) && "ActiveXObject" in window){
            var $nextBut = $(item).find('.swiper-button-next');
            var $prevBut = $(item).find('.swiper-button-prev');       
            $nextBut.attr({style:"background-position: 50% 50% !important;top: 95% !important;"});
            $prevBut.attr({style:"background-position: 50% 50% !important;top: 95% !important;"});
          } else {
        //do nothing
         }
         new Swiper($(item).find('.swiper-container'), {
            speed: 850,
            pagination: {
               el: '#' +id+ ' .swiper-pagination',
               type: 'bullets',
               clickable: true
               },
               navigation: {
               nextEl: '#' +id+ ' .swiper-button-next',
               prevEl: '#' +id+ ' .swiper-button-prev',
               },
               autoplay: {
                   delay: 5000,

               },
               slidesPerView: 'auto',
               loop: true,
               loopedSlides: 2,
         })
      })
   },    
   initTickers: function() {
      this.$tickers.each(function(i, item){
         new Swiper($(item).find('.swiper-container'), {
             speed: 850,
            pagination: {
               el: '.swiper-pagination',
               type: 'bullets',
               clickable: true
             },
             autoplay: {
               delay: 5000,
             },
         })
      })
   },
   initMediaGalleries: function() {
      this.$mediaGallerys.each(function(i, item){
         var id = $(item).attr("id");
         var $swiperElem = $(item).find('.media-gallery_swiper-1');
         var swiperActiveIdx = 0;
         var swiper1 = new Swiper($swiperElem, {
            allowTouchMove: false,
            speed: 400,
            effect: 'fade',
            fadeEffect: {
               crossFade: true
             },
             on: {
               init: function() {
                  var elem = $swiperElem.find('.swiper-slide-active .is-youtube .media-gallery_video-player');
                  if (elem && elem.length == 1){
                     var waitForYT = setInterval(function(){
                        if (YT.loaded){
                           clearInterval(waitForYT);
                           jdiGlobalObj.playMediaGalleryYoutubeVideo(elem, false);
                        }
                     }, 500);
                  }
                  var v_elem = $swiperElem.find('.swiper-slide-active .is-vimeo .media-gallery_video-player');
                  if (v_elem && v_elem.length == 1){
                     jdiGlobalObj.playMediaGalleryVimeoVideo(v_elem, false);
                  }
               },
               slideChangeTransitionStart: function() {
                  if (jdiGlobalObj.activePlayer) {      
                     if (jdiGlobalObj.activePlayer.type === 'vimeo') {
                        jdiGlobalObj.activePlayer.player.pause();

                     } else if (jdiGlobalObj.activePlayer.type === 'youtube') {
                        var elem = $swiperElem.find('.swiper-slide').eq(swiperActiveIdx).find('.is-youtube .media-gallery_video-player');
                        if (elem && elem.length > 1){
                           elem[0].contentWindow.postMessage(
                           '{"event":"command","func":"pauseVideo","args":""}',
                           "*"
                           );
                        }
                     }
                  }
               },
               slideChangeTransitionEnd: function() {
                  swiperActiveIdx = swiper1.activeIndex;
                  if ($(item).find('.swiper-slide-active .is-vimeo').length) {
                     jdiGlobalObj.playMediaGalleryVimeoVideo($swiperElem.find('.swiper-slide-active .is-vimeo .media-gallery_video-player'), true);
                  } else if ($(item).find('.swiper-slide-active .is-youtube').length) {
                     jdiGlobalObj.playMediaGalleryYoutubeVideo($swiperElem.find('.swiper-slide-active .is-youtube .media-gallery_video-player'), true);
                  } else {
                     if (jdiGlobalObj.activePlayer) {
                        jdiGlobalObj.activePlayer = {};
                     }
                  }
               }
             }
         })
         new Swiper($(item).find('.media-gallery_swiper-2'), {
            speed: 400,
            allowTouchMove: false,
            slideToClickedSlide: true,
            navigation: {
               nextEl: '#' + id + ' .swiper-button-next',
               prevEl: '#' + id + ' .swiper-button-prev',
            },
            on: {
               slideNextTransitionStart: function(e) {
                  swiper1.slideNext();
               },
               slidePrevTransitionStart: function(e) {
                  swiper1.slidePrev();

               },
               slideChangeTransitionEnd: function(e){
                  if (this.activeIndex != swiper1.activeIndex){
                     swiper1.slideTo(this.activeIndex);
                  }
               }
            },
            // slidesPerView: 'auto',
            // loop: true,
            // loopedSlides: 5,
            slidesPerView: 5,
            centeredSlides: true,
            spaceBetween: 10
         })
      })

      this.getMediaGalleryVimeoThumbs($('.media-gallery_video.is-vimeo:not(.has-img)'));
   },
   playMediaGalleryVimeoVideo: function(elem, autoplay) {
      elem.closest('.media-gallery_video').find('.media-gallery_media-item, .media-gallery_description').fadeOut(200)
      
      var playerVim = new Vimeo.Player(elem[0], {
         id: elem.attr('data-id')
     })
     playerVim.on('loaded', function(){
        if (autoplay){
         playerVim.setVolume(0);
         playerVim.play();
        }
         
     })
     jdiGlobalObj.activePlayer = { type: 'vimeo', player: playerVim };

     playerVim.play();
   },
   playMediaGalleryYoutubeVideo: function(elem, autoplay) {
      elem.closest('.media-gallery_video').find('.media-gallery_media-item, .media-gallery_description').fadeOut(200)
      var initYtid = ($(".media-gallery_video-player").attr('data-id'));
      var $initElem = ($(".media-gallery_video-player")[0])
      var ytid = elem.attr('data-id');
      if (!ytid && !initYtid) return;
      
      new YT.Player(elem[0] ? elem[0] : $initElem, {
         height: '100%',
         width: '100%',
         videoId: elem.attr('data-id') ? elem.attr('data-id') : initYtid,
         playerVars: {rel: 0, showInfo: 0},
         events: {
           'onReady': function(event) {
              event.target.mute();
              // remove conditional to always enable autoplay
              if (autoplay){
               event.target.playVideo();
              }
           }
         }
      });

      jdiGlobalObj.activePlayer = { type: 'youtube', player: elem };

      if (elem[0].contentWindow) {
         elem[0].contentWindow.postMessage(
            '{"event":"command","func":"playVideo","args":""}',
            "*"
         );
      }
   },
   accordionHandler: function(e) {
      var $target = $(e.currentTarget);

      if ($target.parent().hasClass('is-active')) {
         $target.parent().removeClass('is-active').find('.accordion_body').slideToggle(200);
      } else {
         $target.closest('.accordion_topic').find('.accordion_item').each(function(i, item) {
            if ($(item).hasClass('is-active')) {
               $(item).find('.accordion_body').slideToggle(200);
               $(item).removeClass('is-active')
            }
         })
         $target.parent().addClass('is-active').find('.accordion_body').slideToggle(200);
      }
         
   },
   handleTextWImgLightboxOpen: function(e) { 
      var $target = $(e.target);
      var $lightboxContent = $target.closest('.text-w-img_col-2');
      
      $target.closest('.text-w-img.has-light-box')
         .find('.text-w-img_lightbox-content')
         .css("display", "flex")
         .end()
         //.css({ height: $lightboxContent[0].scrollHeight + 'px' })
         .find('.text-w-img_lightbox-content')
         .hide()
         .fadeIn(200)
         .end()
         .find('.text-w-img_wysiwyg-wrap, .f-body:not(.text-w-img_lightbox-content), .text-w-img_lightbox-trig-wrap')
         //.addClass('blur-2px');
   },
   handleTextWImgLightboxClose: function(e) {
      var $target = $(e.target);
      $target.closest('.text-w-img_lightbox-content')
         .fadeOut(200)
         .end()
         .closest('.text-w-img_col-2')
         //.css({ height: 'initial' })
         .find('.text-w-img_wysiwyg-wrap, .f-body:not(.text-w-img_lightbox-content), .text-w-img_lightbox-trig-wrap')
         //.removeClass('blur-2px');
   },
   playVimeoIframe: function() {
      var element = $(this).closest('.cover').find('.video-player_player')[0];
      var player = new Vimeo.Player(element, {
          id: $(this).closest('.cover').find('.video-player.vimeo').attr('data-id')
      })
      player.on('loaded', function(event){
         player.setVolume(0);
         //jdiGlobalObj.launchIntoFullscreen(element);
         $(element).closest('.cover').find('.cover_text-wrap').fadeOut(200);
         player.play();
      })
   },
   playVimeoIframe2: function() {
      this.$iwtVimeo.each(function(i, item){
         var $id = $(item).find('.vimeo-player').attr('data-id');
         var $element = $(item).find('.vimeo-player');
         var $autoplay = $(item).find('.vimeo-player').attr('auto');
         
         var player = new Vimeo.Player($element, {
            id: $id
         })
         player.on('loaded', function(event){
           if($autoplay == 1) {
              player.setVolume(0);
              player.play();
           }
         })

      })

   },
   playYTIframe2: function() {
      
       (function loadYoutubeIFrameApiScript() {
         var tag = document.createElement("script");
         tag.src = "https://www.youtube.com/iframe_api";
     
         var firstScriptTag = document.getElementsByTagName("script")[0];
         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
     
         tag.onload = setupPlayer;
       })();

       var players = [];
    
       function setupPlayer() {
         window.YT.ready(function() {
            jdiGlobalObj.$iwtYt.each(function(i, item)
            {
              var $autoplay =  $(item).find('.ytplayer').attr('auto');
              var $videoId = $(item).find('.ytplayer').attr('data-id');
              var $vidPlayer = $(item).find('.ytplayer').attr('id');
              var player = new window.YT.Player($vidPlayer, {
                 height: "auto",
                 width: "100%",
                 videoId: $videoId,
                 events: {
                    'onReady': function(event) {
                       if($autoplay == 1) {
                          event.target.mute();
                          jdiGlobalObj.launchIntoFullscreen(event.target.a);
                          event.target.playVideo();
                        }
                     }
                  }
               });
               players.push(player);
            })
         });
       }
      
   },
   playYTIframe: function() {
      new YT.Player($(this).closest('.cover').find('.video-player_player')[0], {
         height: '100%',
         width: '100%',
         videoId: $(this).closest('.cover').find('.video-player.youtube').attr('data-id'),
         playerVars: {rel: 0, showInfo: 0},
         events: {
           'onReady': function(event) {
              event.target.mute();
              jdiGlobalObj.launchIntoFullscreen(event.target.a);
              event.target.playVideo();
           }
         }
      });
   },
   launchIntoFullscreen: function(element) {
      $(element).closest('.cover').find('.cover_text-wrap').fadeOut(200);
      
      setTimeout(function(){
         if(element.requestFullscreen) {
            element.requestFullscreen();
         } else if(element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
         } else if(element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
         } else if(element.msRequestFullscreen) {
            element.msRequestFullscreen();
         }
      },200)   
   },
   getMediaGalleryVimeoThumbs: function(vimeoThumbs) {
      for(var i = 0; i < vimeoThumbs.length; i++) {
         (function(){
            var idx = i;
            var id = vimeoThumbs.eq(idx).attr('data-id');
               $.ajax({
                  type:'GET',
                  url: 'https://vimeo.com/api/v2/video/' + id + '.json',
                  jsonp: 'callback', 
                  jsonpCallback: 'jQuery',
                  dataType: 'jsonp',
                  success: function(data){
                     var thumbnailSrc = data[0].thumbnail_large;
                     vimeoThumbs.eq(idx).find('.media-gallery_media-item').css('background-image', 'url(' + thumbnailSrc + ')');
                  }
            });
         }())   
      }
   },
   getVimeoThumbs: function() {
      for(var i = 0; i < this.vimeoPlayBtns.length; i++) {
         (function(){
            var idx = i;
            var id = jdiGlobalObj.vimeoPlayBtns.eq(idx).closest('.cover').find('.video-player.vimeo').attr('data-id');
               $.ajax({
                  type:'GET',
                  url: 'https://vimeo.com/api/v2/video/' + id + '.json',
                  jsonp: 'callback',
                  jsonpCallback: 'jQuery',
                  dataType: 'jsonp',
                  success: function(data){
                     var thumbnailSrc = data[0].thumbnail_large;
                     jdiGlobalObj.vimeoPlayBtns.eq(idx).closest('.cover').find('.video-player_placeholder.replace-bg').css('background-image', 'url(' + thumbnailSrc + ')');
                  }
            });
         }())   
      }   
    },
    adjustNewsItemByCategory: function (catID) {
        this.$categoryIdField.val(catID);
        this.$newsCategoryForm.submit();
    },
    scrollTo: function (hash) {
        location.hash = "#" + hash;
    },
    tabsClickHandler: function(e) {
      var currActive = this.$tabsHeaders.index($('.is-active'));
      var nextActive = this.$tabsHeaders.index($(e.currentTarget));

      this.$tabsHeaders.eq(currActive).removeClass('is-active');
      this.$tabsContents.eq(currActive).fadeOut(150, function(){
         this.$tabsContents.eq(nextActive).fadeIn(150);
         this.$tabsHeaders.eq(nextActive).addClass('is-active');
      }.bind(this))
   },
   getiFrameheight: function() {
      //check if height is defined by the user set all children to same height
      if ($("section.codesnippetblock").attr("style").includes("px")) {
         var $height = $("section.codesnippetblock").css("height")
         $("section.codesnippetblock").find("*").height($height)
      } 
      //otherwise get height from iframe block and set parent elements to same height
      else {
         var $height =  $(".codesnippetblock iframe").attr("height")
         var $el = $("section.codesnippetblock").height($height)
         $el.find("*").height($height)
      }
      //carrers iframe code snippet
      // NOTE must use iframe instead of script since script loads AFTER jquery can grab elements to calculate height
      // <p><iframe scrolling="no" src="//jdi.referrals.selectminds.com/widget_job_results/15521002?link_color=%23005EB8&amp;has_header=10&amp;title=Careers at UTT&amp;uid=691" height="800" width="400" frameborder="0" allowtransparency="true"></iframe></p>
   }
}


